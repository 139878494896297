<route>
{
  "meta": {
    "auth": "exportRecord"
  }
}
</route>

<template>
  <div>
    <el-card class="tableContainer">
      <div class="header" slot="header">
        <span class="title">导出列表</span>

        <el-button size="mini" style="float: right" type="success" @click="getList">刷新列表</el-button>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" type="index" label="排行"> </el-table-column>
        <el-table-column align="center" prop="exportName" label="名称"> </el-table-column>

        <el-table-column align="center" prop="distributionMoney" label="执行状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.exportStatus == 3" type="danger"> {{ status[scope.row.exportStatus] }}</el-tag>
            <el-tag v-else> {{ status[scope.row.exportStatus] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="inventory" label="创建者">
          <template>
            {{ $store.getters['user/userInfo'].name }}
          </template>
        </el-table-column>

        <!-- 先取登录账号的，因为目前只能取自己 -->
        <el-table-column align="center" prop="saleTimeStart" label="创建时间" width="140">
          <template slot-scope="scope">
            <div>{{ scope.row.createTime | formatTime }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="180">
          <template slot-scope="{ row }">
            <el-button :disabled="row.exportStatus != 2" @click="confirmDownload(row)" type="text" size="small">
              下载链接</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      status: {
        0: '正在准备执行导出',
        1: '正在导出',
        2: '导出成功',
        3: '导出失败'
      }
    }
  },
  methods: {
    confirmDownload(row) {
      this.$store
        .dispatch('upload/getFileUrlWidthSignature', row.exportUrl)
        .then(response => {
          window.open(response.replace('http:', '').replace('https:', ''))
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },

    // 请求后端获取商品排行列表
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      this.$http
        .get('/boom-center-statistics-service/sysAdmin/exportRecord/page', {
          params: {
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less"></style>
